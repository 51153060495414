import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tag,
} from "antd";
import React from "react";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";

import "dayjs/locale/id"; // Import the Indonesian locale
import { useRecapDaily } from "hooks/attandance-hook/useRecapDaily";
import * as XLSX from "xlsx";
import { decryptCookies } from "components/Helper/CookiesHelper";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import { useClassListFoundation } from "hooks/classes-hook/useClassListFoundation";
import useResetPage from "components/Helper/useResetPage";

dayjs.locale("id");

const style = {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
  gap: 5,
};

const RecapMasterDaily = () => {
  const [form] = Form.useForm();
  const [keyName, setKeyName] = useState("");
  const [dateIn, setDateIn] = useState(dayjs().format("YYYY-MM-DD"));
  const [dateOut, setDateOut] = useState(dayjs().format("YYYY-MM-DD"));
  const [recap, setRecap] = useState("kelas");
  const [classId, setClassId] = useState("");
  const [foundationId, setFoundationId] = useState("");
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");
  const [isMissing, setIsMissing] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const [dataTable, setDataTable] = useState({
    current_page: 1,
    per_page: 15,
    total: 0,
  });

  const { data, isFetching } = useRecapDaily(
    dataTable,
    recap,
    dateIn,
    dateOut,
    keyName,
    classId,
    foundationId,
    isMissing,
    true
  );

  const { data: dataClass } = useClassListFoundation(foundationId);

  // fetch foundation list
  const { data: dataFoundation } = useFoundationList();

  // Default page size for pagination
  useEffect(() => {
    if (isInitialRender && data) {
      setDataTable((prevData) => ({
        ...prevData,
        per_page: data?.data?.meta?.total,
      }));
      setIsInitialRender(false);
    }
  }, [data, isInitialRender]);

  useEffect(() => {
    form.setFieldsValue({
      recap: recap,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dataTable.current_page = 1;
    // eslint-disable-next-line
  }, [recap]);

  const handleShowPresence = ({ title, content }) => {
    Modal.info({
      title,
      content: (
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>{content}</div>
      ),
      style: { top: 20 },
      width: 600,
    });
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      fixed: "left",
      width: window.innerWidth > 800 ? 60 : 50,
      responsive: ["md"],
    },
    {
      title: "Nama",
      dataIndex: "student_name",
      key: "",
      width: window.innerWidth > 800 ? 100 : 70,
      fixed: "left",
      hiddenClass: true,
    },
    {
      title: "Nis",
      dataIndex: "nis",
      key: "",
      hiddenClass: true,
    },
    {
      title: "Kelas ",
      dataIndex: "class_name",

      align: "left",
      width: window.innerWidth > 800 ? 100 : 70,
      render: (text, record) => {
        return (
          <Button type="link" onClick={() => handleShowDetail(record.class_id)}>
            {text}
          </Button>
        );
      },
    },
    {
      title: "Total Siswa",
      dataIndex: "total_student",
      key: "total_student",
      align: "left",
      hiddenStudent: true,
    },
    {
      title: "Tidak Ada Presensi",
      dataIndex: "missing_days",
      render: (text, record) => {
        return (
          <Tag
            className="cursor-pointer"
            color="blue"
            onClick={() =>
              handleShowPresence({
                title: "List Tidak Ada Presensi",
                content: text?.map((day, index) => (
                  <pre key={index}>{day}</pre>
                )),
              })
            }
          >
            Detail - ({record?.missing_days_count})
          </Tag>
        );
      },
    },
    {
      title: "Hadir",
      dataIndex: "hadir",
      key: "hadir",
      align: "left",
    },
    {
      title: "Sakit",
      dataIndex: "sakit",
      key: "sakit",
      align: "left",
    },
    {
      title: "Izin",
      dataIndex: "izin",
      key: "izin",
      align: "left",
    },
    {
      title: "Alpa",
      dataIndex: "alpa",
      key: "alpa",
      align: "left",
    },
    {
      title: "Akumulasi Kehadiran",
      dataIndex: "present_accumulation",
      key: "",
      align: "left",
      render: (text) => {
        return <>{text}%</>;
      },
    },
  ];

  // filter columns berdasarkan recap yang dipilih ---
  const filteredColumns = columns.filter((column) => {
    if (recap === "siswa") {
      return !column.hiddenStudent;
    } else {
      return !column.hiddenClass;
    }
  });

  const pagination = {
    current: dataTable.current_page,
    pageSize: dataTable.per_page,
    total: data?.data?.meta?.total,
    showSizeChanger: true,
    pageSizeOptions: [15, 20, 50, 100, data?.data?.meta?.total], // Menambahkan ukuran halaman sesuai dengan total data
    onChange: (curr, size) => {
      if (size === data?.data?.meta?.total) {
        // Jika ukuran halaman sama dengan total data
        setDataTable((prev) => {
          return {
            ...prev,
            current_page: 1, // Atur nomor halaman menjadi 1 agar semua data ditampilkan
            per_page: size,
          };
        });
      } else {
        // Jika ukuran halaman berbeda dengan total data, jalankan seperti biasa
        setDataTable((prev) => {
          return {
            ...prev,
            current_page: curr,
            per_page: size,
          };
        });
      }
    },
  };

  const dataSources = data?.data.data
    .slice(0, dataTable.per_page)
    .map((item, index) => {
      const dataKehadiran = {
        // comment : join total dan persentase
        hadir: `${item.present}  (${item.present_precentage}%)`,
        sakit: `${item.sick}  (${item.sick_precentage}%)`,
        izin: `${item.permission}  (${item.permission_precentage}%)`,
        alpa: `${item.absent}  (${item.absent_precentage}%)`,
      };
      return {
        ...item,
        ...dataKehadiran,
        index: index + 1,
        key: index,
        studentCount: item?.class?.students_count,
      };
    });

  const dataToExport = data?.data?.data.map((datas) => {
    let result;

    const statusRecap = {
      hadir: `${datas.present}  (${datas.present_precentage}%)`,
      sakit: `${datas.sick}  (${datas.sick_precentage}%)`,
      izin: `${datas.permission}  (${datas.permission_precentage}%)`,
      alpa: `${datas.absent}  (${datas.absent_precentage}%)`,
      akumulasi_kehadiran: `${datas.present_accumulation}%`,
    };

    if (recap === "siswa") {
      result = {
        Nama: datas?.student_name,
        NIS: datas?.nis,
        Kelas: datas?.class_name,
        ...statusRecap,
      };
    } else {
      result = {
        Kelas: datas?.class_name,
        Total_Siswa: datas?.total_student,
        ...statusRecap,
      };
    }

    return result;
  });

  const handleExport = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(dataToExport);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, `rekap_absensi.xlsx`);
  };

  const handleShowDetail = (val) => {
    setClassId(val);
    if (recap === "kelas") {
      setRecap("siswa");
      form.setFieldsValue({
        recap: "siswa",
        classId: val,
      });
    } else {
      setRecap("kelas");
      form.setFieldsValue({
        recap: "kelas",
        classId: val,
      });
    }
  };

  useResetPage(setDataTable, [
    recap,
    foundationId,
    classId,
    dateIn,
    dateOut,
    keyName,
    isMissing,
  ]);

  return (
    <div>
      <div className="table-header">
        <h1>Rekap Absensi harian {recap}</h1>
        <Button onClick={handleExport} type="primary">
          Export to Excel
        </Button>
      </div>
      <Form form={form} layout="vertical">
        <div style={style}>
          <Form.Item name="recap" label="Rekap" style={{ marginBottom: 5 }}>
            <Select
              onChange={(e) => setRecap(e)}
              options={[
                {
                  value: "kelas",
                  label: "Kelas",
                },
                {
                  value: "siswa",
                  label: "Siswa",
                },
              ]}
            ></Select>
          </Form.Item>
          {isSuperAdmin && (
            <Form.Item
              name="foundationId"
              label="Yayasan"
              className="form-item-kehadiran"
              style={{ width: "100%", marginBottom: 0 }}
            >
              <Select
                style={{ minWidth: 150 }}
                onChange={(x) => setFoundationId(x)}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option className="select-option-foundation" value="">
                  ALL
                </Select.Option>
                {dataFoundation?.data?.data?.map((x) => (
                  <Select.Option
                    key={x.id}
                    value={x.id}
                    className="select-option-foundation"
                  >
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item name="classId" label="Kelas" style={{ marginBottom: 5 }}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => setClassId(value)}
            >
              <Select.Option value={""}>Semua kelas</Select.Option>
              {dataClass?.data?.map((kelas) => (
                <Select.Option key={kelas.id} value={kelas.id}>
                  {kelas.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="date_in"
            label="Tanggal Mulai"
            initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
            style={{ marginBottom: 5 }}
          >
            <DatePicker
              format="DD/MM/YYYY"
              onChange={(value) => setDateIn(dayjs(value).format("YYYY-MM-DD"))}
            />
          </Form.Item>

          <Form.Item
            name="date_out"
            label="Tanggal Akhir"
            initialValue={dayjs(moment(new Date()).format("YYYY-MM-DD"))}
            style={{ marginBottom: 5 }}
          >
            <DatePicker
              format="DD/MM/YYYY"
              onChange={(value) =>
                setDateOut(dayjs(value).format("YYYY-MM-DD"))
              }
            />
          </Form.Item>

          <Form.Item label="Tidak Ada Presensi">
            <Checkbox onChange={(e) => setIsMissing(e.target.checked)}>
              Tampilkan
            </Checkbox>
          </Form.Item>
        </div>
        <div className="search-wrapper filter-wrapper">
          <Form.Item
            label="Cari berdasarkan nama"
            name="name"
            hidden={recap !== "siswa"}
            onChange={(e) => setKeyName(e.target.value)}
            style={{ marginTop: 10 }}
          >
            <Input placeholder="cari berdasarkan nama" />
          </Form.Item>
        </div>
      </Form>

      <Table
        size={window.innerWidth > 1600 ? "middle" : "small"}
        tableLayout="auto"
        dataSource={dataSources}
        columns={filteredColumns}
        loading={isFetching}
        pagination={pagination}
        scroll={
          window.innerHeight < 690
            ? { y: "52vh", x: 900 }
            : { y: "55vh", x: 900 }
        }
      />
    </div>
  );
};

export default RecapMasterDaily;
