import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  message,
} from "antd";
import axios from "axios";
import { decryptCookies } from "components/Helper/CookiesHelper";
import dayjs from "dayjs";
import { useFoundationList } from "hooks/foundation-hook/useFoundationList";
import React from "react";
import { useState } from "react";

export default function CreateAgenda({ isOpen, isClose }) {
  const [form] = Form.useForm();
  const { REACT_APP_HOST_API } = process.env;
  const [isLoad, setIsLoad] = useState(false);
  const roles = decryptCookies("role").split(",");
  const isSuperAdmin = roles.some((x) => x === "super_admin");

  // get foundation list
  const { data: dataFoundation } = useFoundationList();

  const handleSubmit = async () => {
    const values = await form.validateFields();

    const val = {
      ...values,
      date: values.date.map((x) => dayjs(x).format("YYYY-MM-DD")),
      countPresence: values.countPresence ? true : false,
    };
    setIsLoad(true);
    try {
      await axios.post(`${REACT_APP_HOST_API}/academics/agendas`, val, {
        headers: { Authorization: "Bearer " + decryptCookies("token") },
      });
      message.success("Berhasil menambahkan agenda");
      isClose();
      form.resetFields();
    } catch (error) {
      console.log(error);
      Modal.error({
        title: "Gagal Menambahkan Agenda",
        content: <pre>{error?.response?.data?.message}</pre>,
      });
    } finally {
      setIsLoad(false);
    }
  };

  const handleClose = () => {
    isClose();
    form.resetFields();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleClose}
      closable={false}
      okText="Simpan"
      cancelText="Batal"
      onOk={handleSubmit}
      confirmLoading={isLoad}
    >
      <Form layout="vertical" form={form}>
        {isSuperAdmin && (
          <Form.Item
            name="foundationId"
            label="Foundation"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {dataFoundation?.data?.data?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Nama Agenda"
          name="name"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Tipe"
          name="type"
          initialValue="HOLIDAY"
          rules={[{ required: true, message: "Harap diisi" }]}
        >
          <Select>
            {/* <Select.Option value="NATIONAL_DAY">Hari Nasional</Select.Option> */}
            <Select.Option value="HOLIDAY">Libur</Select.Option>
            {/* <Select.Option value="EVENT">Acara</Select.Option> */}
            <Select.Option value="SCHOOL_AGENDA">Agenda Sekolah</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Deskripsi" name="description">
          <Input />
        </Form.Item>

        <Form.Item name="date" label="Tanggal" rules={[{ required: true }]}>
          <DatePicker multiple maxTagCount="responsive" />
        </Form.Item>

        <Form.Item name="countPresence" valuePropName="checked">
          <Checkbox>Dihitung sebagai presensi?</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}
